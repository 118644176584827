<template>
  <div class="left-splash-wrapper">
    <div class="left-splash">
      <div class="overlay">
        <!-- <h1>Hello World.</h1> -->
        <img
          src="../../assets/images/logo.svg"
          alt="logo"
          class="rounded-3"
          width="30"
        />
        <span>Nouvi</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SplashScreen",
};
</script>
<style>
.left-splash-wrapper {
  width: 50%;
}
.box-form .left-splash {
  color: #ffffff;
  /* background: linear-gradient(180deg, #e6eaee 0%, #d5dee8 100%); */
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url("../../assets/images/login-2.jpg"); */
  overflow: hidden;
  background: #e8f0fe;
  height: 100%;
  position: relative;
}
.left-splash .overlay {
  width: 100%;
  height: 100%;
  /* background: rgba(89, 97, 249, 0.5); */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
}
.left-splash .overlay img {
  width: 30%;
}
.left-splash .overlay span p {
  margin-top: 30px;
  font-weight: 900;
}
.left-splash .overlay span a {
  background: #3b5998;
  color: #ffffff;
  margin-top: 10px;
  padding: 14px 50px;
  border-radius: 100px;
  display: inline-block;
  box-shadow: 0 3px 6px 1px #042d4657;
}
.left-splash .overlay span a:last-child {
  background: #1dcaff;
  margin-left: 30px;
}

.left-splash .overlay span {
  color: #303030;
  font-family: "Roboto", sans-serif;
  font-size: 1.3rem;
}

@media only screen and (max-width: 991px) {
  .left-splash-wrapper{
    width: 100%;
  }
  .box-form .left-splash{
    padding-top: 54px;
    padding-bottom: 30px;
  }
  .box-form .right{
    padding: 30px 30px;
  }

}
</style>
